<template>
  <header class="bg-black text-white fixed top-0 left-0 w-full z-50 shadow-md">
    <!-- Top navigation with logo, search bar, user options, and cart icon -->
    <div class="container mx-auto flex items-center justify-between py-2 px-4 relative">
      <!-- Logo -->
      <div class="flex items-center space-x-2">
        <img src="../assets/logo.png" alt="ELFS Logo" class="h-[100px] w-[280px]" />
      </div>

      <!-- Desktop Search Bar -->
      <div class="hidden lg:flex flex-grow mx-4 relative left-[350px]">
        <input
          type="text"
          placeholder="Cari Produk..."
          class="w-1/2 py-1 px-4 rounded-md focus:outline-none focus:ring focus:border-blue-500"
        />
      </div>

      <!-- Desktop User Account Options -->
      <div class="hidden lg:flex items-center space-x-4 relative right-[80px]">
        <a href="#" class="hover:text-gray-300">Konfirmasi Pembayaran</a>
        <a href="#" class="hover:text-gray-300">Masuk / Daftar</a>
      </div>

      <!-- Cart Icon aligned to the far right for desktop and mobile -->
      <div class="flex items-center absolute right-0">
        <button class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded flex items-center">
          <svg
            class="w-5 h-5 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 3h18l-1 7H4L3 3zM7 13v6a3 3 0 006 0v-6"
            ></path>
          </svg>
          0
        </button>
      </div>

      <!-- Mobile Menu Icon -->
      <button @click="toggleMobileMenu" class="lg:hidden text-white flex items-center absolute right-20">
        <svg
          v-if="!isMobileMenuOpen"
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
        <svg
          v-else
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>
    </div>

    <!-- Bottom Navigation (Visible on Desktop Only) -->
    <nav class="bg-white py-2 hidden lg:block">
      <ul class="flex justify-center space-x-6 text-black">
        <li><a href="#" class="hover:text-blue-500">ATASAN</a></li>
        <li><a href="#" class="hover:text-blue-500">LUARAN</a></li>
        <li><a href="#" class="hover:text-blue-500">BAWAHAN</a></li>
        <li><a href="#" class="hover:text-blue-500">TAS</a></li>
        <li><a href="#" class="hover:text-blue-500">TOPI</a></li>
        <li><a href="#" class="hover:text-blue-500">DASI</a></li>
        <li><a href="#" class="hover:text-blue-500">AKSESORIS</a></li>
        <li><a href="#" class="hover:text-blue-500">KAOS KAKI</a></li>
        <li><a href="#" class="hover:text-blue-500">OUTDOOR</a></li>
        <li><a href="#" class="hover:text-blue-500">ANAK</a></li>
        <li><a href="#" class="hover:text-blue-500">MUSLIM</a></li>
      </ul>
    </nav>

    <!-- Mobile Menu -->
    <div
      v-if="isMobileMenuOpen"
      class="fixed inset-0 bg-black bg-opacity-50 z-50 lg:hidden"
      @click="closeMobileMenu"
    >
      <div
        class="absolute top-0 left-0 w-64 bg-white text-black shadow-lg h-full"
        @click.stop
      >
        <!-- Mobile Search Bar with Black Background -->
        <div class="mb-4 flex items-center justify-between bg-black p-2">
          <input
            type="text"
            placeholder="Cari Produk..."
            class="w-full py-1 px-4 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-gray-300 bg-gray-900"
          />
          <button @click="closeMobileMenu" class="ml-2 text-white">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>

        <!-- Mobile Links -->
        <ul>
          <!-- Black Background for First Two Items -->
          <li class="bg-black text-white px-4 py-2 flex items-center space-x-2">
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <a href="#" class="block">Masuk / Daftar</a>
          </li>
          <li class="bg-black text-white px-4 py-2 flex items-center space-x-2 border-t border-gray-600">
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            <a href="#" class="block">Konfirmasi Pembayaran</a>
          </li>

          <!-- White Background for Other Menu Items -->
          <li class="px-4 py-2 flex items-center space-x-2 border-t border-gray-300"><a href="#" class="block">Atasan</a></li>
          <li class="px-4 py-2 flex items-center space-x-2 border-t border-gray-300"><a href="#" class="block">Luaran</a></li>
          <li class="px-4 py-2 flex items-center space-x-2 border-t border-gray-300"><a href="#" class="block">Bawahan</a></li>
          <li class="px-4 py-2 flex items-center space-x-2 border-t border-gray-300"><a href="#" class="block">Tas</a></li>
          <li class="px-4 py-2 flex items-center space-x-2 border-t border-gray-300"><a href="#" class="block">Topi</a></li>
          <li class="px-4 py-2 flex items-center space-x-2 border-t border-gray-300"><a href="#" class="block">Dasi</a></li>
          <li class="px-4 py-2 flex items-center space-x-2 border-t border-gray-300"><a href="#" class="block">Aksesoris</a></li>
          <li class="px-4 py-2 flex items-center space-x-2 border-t border-gray-300"><a href="#" class="block">Kaos Kaki</a></li>
          <li class="px-4 py-2 flex items-center space-x-2 border-t border-gray-300"><a href="#" class="block">Outdoor</a></li>
          <li class="px-4 py-2 flex items-center space-x-2 border-t border-gray-300"><a href="#" class="block">Anak</a></li>
          <li class="px-4 py-2 flex items-center space-x-2 border-t border-gray-300"><a href="#" class="block">Muslim</a></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      isMobileMenuOpen: false,
    };
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
  },
};
</script>

<style scoped>
/* Add specific styles if needed */
</style>

<template>
    <section class="py-8">
      <div class="container mx-auto">
        <h2 class="text-center text-2xl font-bold mb-6">Follow on Instagram</h2>
  
        <!-- Slider Wrapper -->
        <div
          class="relative overflow-hidden"
          @touchstart="handleTouchStart"
          @touchmove="handleTouchMove"
          @touchend="handleTouchEnd"
        >
          <!-- Slider Content -->
          <div ref="slider" class="flex transition-transform duration-500 ease-in-out">
            <!-- Instagram Image 1 -->
            <div class="flex-shrink-0 w-full sm:w-1/2 md:w-1/4 p-2">
              <div class="relative">
                <img
                  src="../assets/instagram/1.webp"
                  alt="Instagram Image 1"
                  class="w-full h-64 object-cover rounded-md"
                />
              </div>
            </div>
  
            <!-- Instagram Image 2 -->
            <div class="flex-shrink-0 w-full sm:w-1/2 md:w-1/4 p-2">
              <div class="relative">
                <img
                  src="../assets/instagram/2.webp"
                  alt="Instagram Image 2"
                  class="w-full h-64 object-cover rounded-md"
                />
              </div>
            </div>
  
            <!-- Instagram Image 3 -->
            <div class="flex-shrink-0 w-full sm:w-1/2 md:w-1/4 p-2">
              <div class="relative">
                <img
                  src="../assets/instagram/3.webp"
                  alt="Instagram Image 3"
                  class="w-full h-64 object-cover rounded-md"
                />
              </div>
            </div>
  
            <!-- Instagram Image 4 -->
            <div class="flex-shrink-0 w-full sm:w-1/2 md:w-1/4 p-2">
              <div class="relative">
                <img
                  src="../assets/instagram/1.webp"
                  alt="Instagram Image 4"
                  class="w-full h-64 object-cover rounded-md"
                />
              </div>
            </div>
  
            <!-- Instagram Image 5 -->
            <div class="flex-shrink-0 w-full sm:w-1/2 md:w-1/4 p-2">
              <div class="relative">
                <img
                  src="../assets/instagram/2.webp"
                  alt="Instagram Image 5"
                  class="w-full h-64 object-cover rounded-md"
                />
              </div>
            </div>
          </div>
  
          <!-- Navigation Buttons -->
          <button @click="prevSlide" class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full">
            &#10094;
          </button>
          <button @click="nextSlide" class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full">
            &#10095;
          </button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'InstagramFeed',
    data() {
      return {
        currentSlide: 0,
        slidesToShow: 1,
        startX: 0,
        endX: 0,
        autoScrollInterval: null,
      };
    },
    mounted() {
      this.updateSlidesToShow();
      window.addEventListener('resize', this.updateSlidesToShow);
      this.startAutoScroll();
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateSlidesToShow);
      this.stopAutoScroll();
    },
    methods: {
      updateSlidesToShow() {
        const width = window.innerWidth;
        if (width >= 1024) {
          this.slidesToShow = 4; // Display 4 images at a time on large screens
        } else if (width >= 640) {
          this.slidesToShow = 2; // Display 2 images at a time on medium screens
        } else {
          this.slidesToShow = 1; // Display 1 image at a time on small screens
        }
        this.updateSliderPosition();
      },
      nextSlide() {
        const maxSlideIndex = Math.ceil(5 / this.slidesToShow) - 1;
        this.currentSlide = (this.currentSlide + 1) % (maxSlideIndex + 1);
        this.updateSliderPosition();
      },
      prevSlide() {
        const maxSlideIndex = Math.ceil(5 / this.slidesToShow) - 1;
        this.currentSlide = (this.currentSlide - 1 + (maxSlideIndex + 1)) % (maxSlideIndex + 1);
        this.updateSliderPosition();
      },
      updateSliderPosition() {
        const slider = this.$refs.slider;
        slider.style.transform = `translateX(-${this.currentSlide * (100 / this.slidesToShow)}%)`;
      },
      handleTouchStart(event) {
        this.startX = event.touches[0].clientX;
        this.stopAutoScroll();
      },
      handleTouchMove(event) {
        this.endX = event.touches[0].clientX;
      },
      handleTouchEnd() {
        const distance = this.endX - this.startX;
        if (distance > 50) {
          this.prevSlide();
        } else if (distance < -50) {
          this.nextSlide();
        }
        this.startAutoScroll();
      },
      startAutoScroll() {
        this.autoScrollInterval = setInterval(this.nextSlide, 3000);
      },
      stopAutoScroll() {
        clearInterval(this.autoScrollInterval);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Additional styles can be added here if needed */
  </style>
  
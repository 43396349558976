<template>
  <section class="py-8">
    <div class="container mx-auto">
      <h2 class="text-center text-2xl font-bold mb-6">Latest Products</h2>

      <!-- Slider Wrapper -->
      <div
        class="relative overflow-hidden"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
      >
        <!-- Slider Content -->
        <div ref="slider" class="flex transition-transform duration-500 ease-in-out">
          <!-- Product 1 -->
          <div class="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 p-4">
            <div class="relative group bg-white rounded-lg shadow-md overflow-hidden p-4">
              <!-- Product Content -->
              <div class="text-center flex flex-col items-center justify-center">
                <!-- Image Wrapper -->
                <div class="relative w-full md:w-1/2 flex items-center justify-center">
                  <img
                    src="../assets/products/1.webp"
                    alt="Rockbros Arm Sleeve Sleeve Breathable Mesh Marine"
                    class="w-full h-64 object-cover transition-opacity duration-300 group-hover:opacity-0"
                  />
                  <img
                    src="../assets/products/1hover.webp"
                    alt="Rockbros Arm Sleeve Sleeve Breathable Mesh Marine Hover"
                    class="w-full h-64 object-cover transition-opacity duration-300 absolute top-0 left-0 opacity-0 group-hover:opacity-100"
                  />
                </div>
                <!-- Description -->
                <h3 class="text-lg font-semibold mt-4">It's so cool</h3>
                <p class="mt-2 text-sm text-gray-800">ROCKBROS ARM SLEEVE SET SCOTCHLITE REFLECTIVE WHITE SLEEVE</p>
                <p class="text-red-500 font-bold text-lg mt-2">Rp.75,000</p>
              </div>
            </div>
          </div>

          <!-- Product 2 -->
          <div class="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 p-4">
            <div class="relative group bg-white rounded-lg shadow-md overflow-hidden p-4">
              <!-- Product Content -->
              <div class="text-center flex flex-col items-center justify-center">
                <!-- Image Wrapper -->
                <div class="relative w-full md:w-1/2 flex items-center justify-center">
                  <img
                    src="../assets/products/2.webp"
                    alt="Rockbros Arm Sleeve Set Scotchlite Reflective White Sleeve"
                    class="w-full h-64 object-cover transition-opacity duration-300 group-hover:opacity-0"
                  />
                  <img
                    src="../assets/products/1hover.webp"
                    alt="Rockbros Arm Sleeve Set Scotchlite Reflective White Sleeve Hover"
                    class="w-full h-64 object-cover transition-opacity duration-300 absolute top-0 left-0 opacity-0 group-hover:opacity-100"
                  />
                </div>
                <!-- Description -->
                <h3 class="text-lg font-semibold mt-4">It's so cool</h3>
                <p class="mt-2 text-sm text-gray-800">ROCKBROS ARM SLEEVE BLACK SCOTCHLITE REFLECTIVE SLEEVE</p>
                <p class="text-red-500 font-bold text-lg mt-2">Rp.75,000</p>
              </div>
            </div>
          </div>

          <!-- Product 3 -->
          <div class="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 p-4">
            <div class="relative group bg-white rounded-lg shadow-md overflow-hidden p-4">
              <!-- Product Content -->
              <div class="text-center flex flex-col items-center justify-center">
                <!-- Image Wrapper -->
                <div class="relative w-full md:w-1/2 flex items-center justify-center">
                  <img
                    src="../assets/products/3.webp"
                    alt="Topi Flatcap Painter Katun Tartan 4 Coklat Muda"
                    class="w-full h-64 object-cover transition-opacity duration-300 group-hover:opacity-0"
                  />
                  <img
                    src="../assets/products/1hover.webp"
                    alt="Topi Flatcap Painter Katun Tartan 4 Coklat Muda Hover"
                    class="w-full h-64 object-cover transition-opacity duration-300 absolute top-0 left-0 opacity-0 group-hover:opacity-100"
                  />
                </div>
                <!-- Description -->
                <h3 class="text-lg font-semibold mt-4">It's so cool</h3>
                <p class="mt-2 text-sm text-gray-800">TOPI FLATCAP PAINTER KATUN TARTAN 4 COKLAT MUDA</p>
                <p class="text-red-500 font-bold text-lg mt-2">Rp.25,000</p>
              </div>
            </div>
          </div>

          <!-- Product 4 -->
          <div class="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 p-4">
            <div class="relative group bg-white rounded-lg shadow-md overflow-hidden p-4">
              <!-- Product Content -->
              <div class="text-center flex flex-col items-center justify-center">
                <!-- Image Wrapper -->
                <div class="relative w-full md:w-1/2 flex items-center justify-center">
                  <img
                    src="../assets/products/4.webp"
                    alt="Product 4"
                    class="w-full h-64 object-cover transition-opacity duration-300 group-hover:opacity-0"
                  />
                  <img
                    src="../assets/products/1hover.webp"
                    alt="Product 4 Hover"
                    class="w-full h-64 object-cover transition-opacity duration-300 absolute top-0 left-0 opacity-0 group-hover:opacity-100"
                  />
                </div>
                <!-- Description -->
                <h3 class="text-lg font-semibold mt-4">New Arrival</h3>
                <p class="mt-2 text-sm text-gray-800">PRODUCT 4 DESCRIPTION</p>
                <p class="text-red-500 font-bold text-lg mt-2">Rp.50,000</p>
              </div>
            </div>
          </div>

          <!-- Product 5 -->
          <div class="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 p-4">
            <div class="relative group bg-white rounded-lg shadow-md overflow-hidden p-4">
              <!-- Product Content -->
              <div class="text-center flex flex-col items-center justify-center">
                <!-- Image Wrapper -->
                <div class="relative w-full md:w-1/2 flex items-center justify-center">
                  <img
                    src="../assets/products/5.webp"
                    alt="Product 5"
                    class="w-full h-64 object-cover transition-opacity duration-300 group-hover:opacity-0"
                  />
                  <img
                    src="../assets/products/1hover.webp"
                    alt="Product 5 Hover"
                    class="w-full h-64 object-cover transition-opacity duration-300 absolute top-0 left-0 opacity-0 group-hover:opacity-100"
                  />
                </div>
                <!-- Description -->
                <h3 class="text-lg font-semibold mt-4">Best Seller</h3>
                <p class="mt-2 text-sm text-gray-800">PRODUCT 5 DESCRIPTION</p>
                <p class="text-red-500 font-bold text-lg mt-2">Rp.60,000</p>
              </div>
            </div>
          </div>

          <!-- Product 6 -->
          <div class="flex-shrink-0 w-full sm:w-1/2 md:w-1/3 p-4">
            <div class="relative group bg-white rounded-lg shadow-md overflow-hidden p-4">
              <!-- Product Content -->
              <div class="text-center flex flex-col items-center justify-center">
                <!-- Image Wrapper -->
                <div class="relative w-full md:w-1/2 flex items-center justify-center">
                  <img
                    src="../assets/products/6.webp"
                    alt="Product 6"
                    class="w-full h-64 object-cover transition-opacity duration-300 group-hover:opacity-0"
                  />
                  <img
                    src="../assets/products/1hover.webp"
                    alt="Product 6 Hover"
                    class="w-full h-64 object-cover transition-opacity duration-300 absolute top-0 left-0 opacity-0 group-hover:opacity-100"
                  />
                </div>
                <!-- Description -->
                <h3 class="text-lg font-semibold mt-4">Limited Edition</h3>
                <p class="mt-2 text-sm text-gray-800">PRODUCT 6 DESCRIPTION</p>
                <p class="text-red-500 font-bold text-lg mt-2">Rp.80,000</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Navigation Buttons -->
        <button @click="prevSlide" class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full">
          &#10094;
        </button>
        <button @click="nextSlide" class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full">
          &#10095;
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LatestProducts',
  data() {
    return {
      currentSlide: 0,
      slidesToShow: 1,
      startX: 0,
      endX: 0,
      autoScrollInterval: null,
    };
  },
  mounted() {
    this.updateSlidesToShow();
    window.addEventListener('resize', this.updateSlidesToShow);
    this.startAutoScroll();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateSlidesToShow);
    this.stopAutoScroll();
  },
  methods: {
    updateSlidesToShow() {
      const width = window.innerWidth;
      if (width >= 1024) {
        this.slidesToShow = 3;
      } else if (width >= 640) {
        this.slidesToShow = 2;
      } else {
        this.slidesToShow = 1;
      }
      this.updateSliderPosition();
    },
    nextSlide() {
      const maxSlideIndex = Math.ceil(6 / this.slidesToShow) - 1;
      this.currentSlide = (this.currentSlide + 1) % (maxSlideIndex + 1);
      this.updateSliderPosition();
    },
    prevSlide() {
      const maxSlideIndex = Math.ceil(6 / this.slidesToShow) - 1;
      this.currentSlide = (this.currentSlide - 1 + (maxSlideIndex + 1)) % (maxSlideIndex + 1);
      this.updateSliderPosition();
    },
    updateSliderPosition() {
      const slider = this.$refs.slider;
      slider.style.transform = `translateX(-${this.currentSlide * (100 / this.slidesToShow)}%)`;
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
      this.stopAutoScroll();
    },
    handleTouchMove(event) {
      this.endX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      const distance = this.endX - this.startX;
      if (distance > 50) {
        this.prevSlide();
      } else if (distance < -50) {
        this.nextSlide();
      }
      this.startAutoScroll();
    },
    startAutoScroll() {
      this.autoScrollInterval = setInterval(this.nextSlide, 3000);
    },
    stopAutoScroll() {
      clearInterval(this.autoScrollInterval);
    },
  },
};
</script>

<style scoped>
/* Add specific styles if needed */
</style>

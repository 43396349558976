<template>
  <section class="py-8">
    <div class="container mx-auto flex flex-col items-center justify-center">
      <!-- Centering the grid within the container -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 w-full px-4 md:px-0">
        <!-- Feature 1 -->
        <div class="relative w-full mx-auto">
          <img
            src="../assets/categ/1.webp"
            alt="ELFS Jaket Hoodie"
            class="w-full h-64 md:h-80 object-cover rounded-md"
          />
          <div class="absolute inset-0 bg-black bg-opacity-25 flex items-center justify-center">
            <!-- <h3 class="text-white font-bold text-base md:text-lg">LF <br />JAKET HOODIE</h3> -->
          </div>
        </div>

        <!-- Feature 2 -->
        <div class="relative w-full mx-auto">
          <img
            src="../assets/categ/2.webp"
            alt="ELFS Jaket Hoodie"
            class="w-full h-64 md:h-80 object-cover rounded-md"
          />
          <div class="absolute inset-0 bg-black bg-opacity-25 flex items-center justify-center">
            <!-- <h3 class="text-white font-bold text-base md:text-lg">LF <br />JAKET HOODIE</h3> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeatureSection',
};
</script>

<style scoped>
/* Additional styles can be added here if needed */
</style>

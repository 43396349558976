<template>
  <div>
    <Header />
    <Banner />
    <FeatureSection />
    <LatestProducts />
    <BestProducts />
    <InstagramFeed />
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Banner from './components/Banner.vue';
import FeatureSection from './components/FeatureSection.vue';
import LatestProducts from './components/LatestProducts.vue';
import Footer from './components/Footer.vue';
import BestProducts from './components/BestProducts.vue';
import InstagramFeed from './components/InstagramFeed.vue';

export default {
  name: 'App',
  components: {
    Header,
    Banner,
    FeatureSection,
    LatestProducts,
    BestProducts,
    InstagramFeed,
    Footer,
  },
};
</script>

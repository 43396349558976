<template>
  <footer class="bg-gray-100 py-10">
    <div class="container mx-auto px-4">
      <!-- Top Section: Store, Information, About Us, Follow Us -->
      <div class="grid grid-cols-1 md:grid-cols-4 gap-8 mb-10">
        <!-- Store Section -->
        <!-- <div>
          <h3 class="text-lg font-semibold mb-4">STORE</h3>
          <p>Cordoba D27 greenlake city, cipondoh, tangerang</p>
          <button class="mt-2 text-sm text-gray-500 underline">See Map</button>
          <p class="mt-4 flex items-center">
            <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 24 24">
              <path d="M21 10h-6l-2-2H7V5h10l2 2h2v3zm1-5h-3V3H5v2H2c-1.1 0-2 .9-2 2v13c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-2 14H3V8h18v11z" />
            </svg>
            08179150527
          </p>
          <p class="mt-1 flex items-center">
            <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 24 24">
              <path d="M20 4H4c-1.11 0-1.99.9-1.99 2L2 18c0 1.1.88 2 1.99 2H20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-2 11H6v-2h12v2zm0-4H6V9h12v2z" />
            </svg>
            elfs.shop@gmail.com
          </p>
        </div> -->

        <!-- Information Section -->
        <div>
          <h3 class="text-lg font-semibold mb-4">INFORMATION</h3>
          <ul class="space-y-2">
            <li><a href="#" class="text-gray-700 hover:underline">How to Buy</a></li>
            <li><a href="#" class="text-gray-700 hover:underline">Size Guide</a></li>
            <li><a href="#" class="text-gray-700 hover:underline">Terms & Returns</a></li>
            <li><a href="#" class="text-gray-700 hover:underline">FAQ</a></li>
            <li><a href="#" class="text-gray-700 hover:underline">Newsletter</a></li>
          </ul>
        </div>

        <!-- About Us Section -->
        <div>
          <h3 class="text-lg font-semibold mb-4">ABOUT US</h3>
          <ul class="space-y-2">
            <li><a href="#" class="text-gray-700 hover:underline">About Us</a></li>
            <li><a href="#" class="text-gray-700 hover:underline">Testimonials</a></li>
            <li><a href="#" class="text-gray-700 hover:underline">Artikel & Info</a></li>
            <li><a href="#" class="text-gray-700 hover:underline">Career</a></li>
            <li><a href="#" class="text-gray-700 hover:underline">Reseller</a></li>
            <li><a href="#" class="text-gray-700 hover:underline">Rent a Photo Studio</a></li>
          </ul>
        </div>

        <!-- Follow Us Section -->
        <div>
          <h3 class="text-lg font-semibold mb-4">FOLLOW US</h3>
          <div class="flex space-x-4 mb-4">
            <a href="#"><img src="../assets/icons/Facebook.png" alt="Facebook" class="w-6 h-6"></a>
            <a href="#"><img src="../assets/icons/Whatapp.png" alt="WhatsApp" class="w-6 h-6"></a>
            <a href="#"><img src="../assets/icons/instagram.png" alt="Instagram" class="w-6 h-6"></a>
          </div>
          <div>
            <h4 class="font-semibold mb-2">VERIFIKASI ELFS SHOP</h4>
            <div class="flex space-x-2">
              <img src="../assets/verification/verification1.webp" alt="Verified Icon 1" class="w-20">
              <img src="../assets/verification/verification2.webp" alt="Verified Icon 2" class="w-20">
              <img src="../assets/verification/verification3.webp" alt="Verified Icon 2" class="w-20">
            </div>
          </div>
        </div>
      </div>

      <!-- Middle Section: Payment Methods, Shipping Services, Availability -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 py-6 bg-gray-200">
        <!-- Payment Methods -->
        <div>
          <h3 class="text-lg font-semibold mb-4">PAYMENT METHOD</h3>
          <div class="flex space-x-4">
            <img src="../assets/payment/ABA.png" alt="Mandiri" class="h-8">
            <img src="../assets/payment/Acleda.png" alt="BCA" class="h-8">
            <img src="../assets/payment/Wechat.png" alt="Bank BRI" class="h-8">
          </div>
        </div>

        <!-- Shipping Services -->
        <div>
          <h3 class="text-lg font-semibold mb-4">SHIPPING SERVICES</h3>
          <div class="flex space-x-4">
            <img src="../assets/shipping/gojek.webp" alt="GoJek" class="h-8">
            <img src="../assets/shipping/grab.webp" alt="Grab" class="h-8">
            <img src="../assets/shipping/jne.webp" alt="JNE" class="h-8">
          </div>
        </div>

        <!-- Availability -->
        <div>
          <h3 class="text-lg font-semibold mb-4">WE'RE AVAILABLE IN</h3>
          <div class="flex space-x-4">
            <img src="../assets/platforms/bl.webp" alt="BL" class="h-8">
            <img src="../assets/platforms/lazada.webp" alt="Lazada" class="h-8">
            <img src="../assets/platforms/shopee.webp" alt="Shopee" class="h-8">
            <img src="../assets/platforms/tokopedia.webp" alt="Tokopedia" class="h-8">
          </div>
        </div>
      </div>

      <!-- Bottom Section: Company Information -->
      <div class="bg-white py-6">
        <!-- <div class="container mx-auto px-4">
          <h3 class="text-lg font-semibold mb-4">MEN'S SMELL SHOP</h3>
          <p class="text-sm text-gray-600">
            ELFS SHOP is <strong>MEN'S SMELL SHOP</strong> Men's accessories & sports. In addition to online sales, we have a shop in greenlake city, cipondoh, tangerang, bordering the kosambi thorns of western Jakarta. ELFS SHOP products include shirts, plainshirts, shirts, sweaters, jackets & hoody, koko clothes, pants, underwear, bags, hats, twists, scarves, ties, belts, socks, outdoor & sports equipment, motor accessories, and small children's clothing. ELFS SHOP is a complete online shop for the needs of slang guys with today's ootd style. ELFS SHOP products are domestic and foreign production.
          </p>
          <p class="text-sm text-gray-600 mt-4">
            Elfs Shop has been online since 2007, selling products that are produced abroad and domestically. Providing quality goods at affordable prices and providing fast, friendly, and trusted service. Elfs shop still cares about the environment by using recycled paper, and co-constructs to WWF and the rope of love foundation every month. Elfs Shop Man Fashion serves import directly from China for any product with a minimum purchase of 50-100pcs and above, mixing various models of 1 type of item. Example: 10 belt models x 5pcs.
          </p>
        </div> -->
      </div>
    </div>
    <div class="text-center py-4 text-sm bg-black text-white">
      © 2024 - radjaart All Rights Reserved
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
/* Add specific styles if needed */
</style>
